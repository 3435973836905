import { styled } from '@mui/system'
import { colors } from '../consts'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

type ShoppingBagProps = {
  color?: string
  height?: number
  width?: number
  fontSize?: number
}

const ShoppingBagCon = styled(ShoppingBagIcon)<ShoppingBagProps>(
  ({ theme, color, height }) => ({
    color: color || colors.black,
    height: height || 50,
  })
)

export const ShoppingIcon: React.FC<ShoppingBagProps> = ({
  color,
  height,
  fontSize = 'inherit',
}) => {
  return (
    <ShoppingBagCon
      height={height}
      sx={{ fontSize: fontSize, color: color || colors.black }}
    />
  )
}