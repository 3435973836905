import { styled } from '@mui/system'
import { colors } from '../consts'
import BarChartIcon from '@mui/icons-material/BarChart';

type StatsProps = {
  color?: string
  height?: number
  width?: number
  fontSize?: number
}

const StatsCon = styled(BarChartIcon)<StatsProps>(
  ({ theme, color, height }) => ({
    color: color || colors.black,
    height: height || 50,
  })
)

export const StatsIcon: React.FC<StatsProps> = ({
  color,
  height,
  fontSize = 'inherit',
}) => {
  return (
    <StatsCon
      height={height}
      sx={{ fontSize: fontSize, color: color || colors.black }}
    />
  )
}