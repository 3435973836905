import { PLLNationUser } from '../../Api/types/users.types'
import { PageNavList } from '../../Components/Navbar/PageNav/PageNav'
import { LEADERBOARD_TAB_NAMES } from '../../Configs/Tabs/tabs.config'
import { useHomeURLParams } from '../../Context/utils'
import { addCommas, stringToNumber } from '../formatting'

export const homePages: PageNavList[] = [
  {
    pageId: 0,
    pageTitle: 'compete',
    link: undefined,
  },
  {
    pageId: 1,
    pageTitle: 'profile',
    link: undefined,
  },
  {
    pageId: 2,
    pageTitle: 'benefits',
    link: undefined,
  },
]

/**
 * Get selected tab from Home URL Params.
 * Return 0 if the params are not 0 or 2.
 **/
export const useTabFromUrl = () => {
  let tabId = stringToNumber(useHomeURLParams().tab)
  return !tabId ? 0 : tabId
}

export const getUserIndex = (
  leaders: PLLNationUser[],
  userId: string | null
) => {
  if (!userId) return -1

  let index = leaders.findIndex((lead) => lead.userId === userId)

  if (index !== -1) {
    return index + 1
  }

  return -1
}

export const getSegmentExp = (
  leader: PLLNationUser,
  segment: 'Weekly' | 'Season'
) => {
  if (segment === LEADERBOARD_TAB_NAMES.season){
    const exp = leader?.season?.exp ? leader.season.exp : leader.exp
    return addCommas(exp)
  }
  return addCommas(leader?.weekExp || 0)
}
