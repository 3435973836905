export type StorageOptionsStatus = {
  indexedDB: boolean
  local: boolean
  session: boolean
  cookies: boolean
}

//Cached storage names
export const STORAGE_NAMES = {
  seasonEvents: 'pll_season_events',
  standings: 'standings',
  config_fantasy: 'config_fantasy',
  fantasy_all_players: 'fantasy_all_players',
  fantasy_groups: 'fatasy_groups',
  matchup_user: 'matchup_user',
}
