import React from 'react'
import { styled } from '@mui/system'
import { colors } from '../../Components/consts'
import LockIcon from '@mui/icons-material/Lock';

type LockIconProps = {
  iconColor?: string
  handleClick?: () => void
}

const LockIconCon = styled(LockIcon)<LockIconProps>(
  ({ theme, iconColor }) => ({
    display: 'flex',
    alignItems: 'end',
    color: iconColor || colors.white,
    height: 20,
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
}))

export const LockMUIIcon: React.FC<LockIconProps> = ({ handleClick, iconColor }) => {
  return <LockIconCon fontSize="large" onClick={handleClick} iconColor={iconColor} />
}