import { useParams } from 'react-router-dom'
import { decodeString } from '../Utils'
import { useQuery } from '../Routing'

export type CodeClaimParams = {
  code?: string
  title?: string
  imageUrl?: string
}

export type MatchupParams = {
  challengerId?: string
}

export type HomeParams = {
  tab: string
}

export type PublicProfileParams = {
  publicId: string
}

export type ReleaseParams = {
  id: string
  code: string
  title: string
  mediaId: string
  imageUrl: string
  message: string
  actionLink: string
  actionBtnImg: string
  actionTitle: string
  actionType: string
  required: string
  secondaryTitle: string
  secondaryLink: string
  sLinkPhrase: string
  brazeEventID: string
  gamificationEvent: string
}

/**
 * Get event code to be claimed from URL
 */

export const useCodeClaimURLParams = (): CodeClaimParams => {
  const rparams: Record<string, string | undefined> = useParams()
  const result: CodeClaimParams = {
    code: rparams.code || '',
    title: rparams.title || '',
    imageUrl: rparams.imageUrl || '',
  }
  return result
}

/**
 * Get event code to be claimed from URL
 */

export const useReleaseParams = (): ReleaseParams => {
  const rparams = useQuery()
  const result: ReleaseParams = {
    id: rparams.get('id') || '',
    code: rparams.get('code') || '',
    title: decodeString(rparams.get('title')) || '',
    imageUrl: decodeString(rparams.get('imageUrl')) || '',
    mediaId: rparams.get('mediaId') || '',
    message: decodeString(rparams.get('message')) || '',
    actionLink: rparams.get('actionLink') || '',
    actionBtnImg: rparams.get('actionBtnImg') || '',
    actionTitle: decodeString(rparams.get('actionTitle')) || '',
    actionType: decodeString(rparams.get('actionType')) || '',
    required: rparams.get('required') || '',
    secondaryTitle: decodeString(rparams.get('secondaryTitle')) || '',
    secondaryLink: decodeString(rparams.get('secondaryLink')) || '',
    sLinkPhrase: decodeString(rparams.get('sLinkPhrase')) || '',
    brazeEventID: rparams.get('brazeEventID') || '',
    gamificationEvent: rparams.get('gamificationEvent') || '',
  }
  return result
}

/**
 * Get matchup challenger id from URL
 */
export const useMatchupURLParams = (): MatchupParams => {
  const rparams: Record<string, string | undefined> = useParams()
  const result: MatchupParams = {
    challengerId: rparams.challengerId || '',
  }
  return result
}


/**
 * Get event officialID from URL
 */
export const usePublicProfileUrlParams = (): PublicProfileParams => {
  const rparams: Record<string, string | undefined> = useParams()
  const result: PublicProfileParams = {
    publicId: rparams.publicId || '',
  }
  return result
}

export const useHomeURLParams = (): HomeParams => {
  const rparams: Record<string, string | undefined> = useParams()
  let result: HomeParams = {
    tab: rparams.tab || '0',
  }

  if (result.tab !== '0' && result.tab !== '2') {
    result.tab = '0'
  }

  return result
}
