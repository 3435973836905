export const BRAZE_EVENTS = {
    // VIEWS
    achivements_page_view: 'AchievementsViewController_View',
    pickem_page_view: 'PickemViewController_View',
    profile_no_auth_page_view: 'ProfileNoAuthViewController_View',
    edit_profile_page_view: 'EditProfileViewController_View',
    fans_page_view: 'FansViewController_View',
    fantasy_groups_page_view: 'FantasyGroupsViewController_View',
    fantasy_page_view: 'FantasyViewController_View',
    fantasy_add_players_view: 'FantasyAddPlayerViewController_View',

    //EVENTS
    log_in: 'logged_in',
    on_site_check_in: 'on_site_check_in'
}