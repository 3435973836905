import React, { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { FantasyGroupProvider, PickemProvider } from '../Context'
import {
  LOGIN_URL,
  HOME_URL,
  PICKEM_URL,
  PREDICTOR_URL,
  BRACKET_URL,
  PLLN_PROFILE_URL,
  ACHIEVEMENTS_URL,
  BENEFITS_URL,
  CODE_CLAIM_URL,
  CODE_REDEEM_URL,
  FANTASY_GROUPS_URL,
  FANTASY_GROUPS_ENTER_COMPETITION_URL,
  FANTASY_TEAM_URL,
  FANTASY_URL,
  FANTASY_PLAYERS_URL,
  PLLN_URL,
  BADGES_URL,
  PLLN_EDIT_PROFILE_URL,
  RELEASE_URL,
  FANTASY_GROUPS_COMPETITION_URL,
  OPT_IN_URL,
  FANTASY_MATCHUP_URL,
} from './urls'
import {
  GamificationContextLayout,
  FantasyContextLayout,
  FantasyGroupContextLayout,
} from './ContextLayouts/index'
import FantasyGroupsEnterCompetition from '../Pages/Fantasy/FantasyGroups/FantasyGroupsEnterCompetition'
import { BasePage } from '../Pages/BasePage'
import { Error } from '../Pages/Errors/error'
import { FantasyChallengersContextLayout } from './ContextLayouts/FantasyChallengersContextLayout'

const Homepage = lazy(() => import('../Pages/Home/Homepage'))
const Login = lazy(() => import('../Pages/Login'))
const Pickem = lazy(() => import('../Pages/Pickem/Pickem'))
const Predictor = lazy(() => import('../Pages/Predictor/Predictor'))
const PLLNProfile = lazy(
  () => import('../Pages/PLLNation/PLLNProfile/PLLNProfile')
)
const Achievements = lazy(
  () => import('../Pages/PLLNation/Achievements/Achievements')
)
const SingleBadge = lazy(
  () => import('../Pages/PLLNation/Badges/SingleBadge')
)

const Benefits = lazy(
  () => import('../Pages/PLLNation/Benefits/Benefits')
)

const CodeClaim = lazy(
  () => import('../Pages/PLLNation/CodeClaim/CodeClaim')
)
const FantasyGroups = lazy(
  () => import('../Pages/Fantasy/FantasyGroups/FantasyGroups')
)
const SingleFantasyGroup = lazy(
  () => import('../Pages/Fantasy/SingleGroup/SingleFantasyGroup')
)
const FantasyTeam = lazy(
  () => import('../Pages/Fantasy/FantasyTeam/FantasyTeam')
)
const FantasyPlayers = lazy(
  () => import('../Pages/Fantasy/FantasyPlayers/FantasyPlayers')
)
const Badges = lazy(() => import('../Pages/PLLNation/Badges/Badges'))
const Release = lazy(() => import('../Pages/PLLNation/Release/Release'))
const OptIn = lazy(() => import('../Pages/PLLNation/OptIn/OptIn'))
const EditProfile = lazy(
  () => import('../Pages/PLLNation/PLLNProfile/EditProfile/EditProfile')
)
const FantasySingleGroupCompetition = lazy(
  () =>
    import('../Pages/Fantasy/GroupCompetition/FantasyGroupCompetition')
)
const Bracket = lazy(() => import('../Pages/Bracket/Bracket'))

const EditFantasyGroupMembers = lazy(
  () =>
    import(
      '../Pages/Fantasy/EditFantasyGroupMembers/EditFantasyGroupMembers'
    )
)
const EditFantasyGroup = lazy(
  () => import('../Pages/Fantasy/EditFantasyGroup/EditFantasyGroup')
)
const FantasyMatchup = lazy(
  () => import('../Pages/Fantasy/FantasyMatchup/FantasyMatchup')
)

export const MainRoutes: React.FC = () => (
  <Routes>
    <Route
      path={LOGIN_URL}
      element={
        <Suspense fallback={<BasePage />}>
          <Login />
        </Suspense>
      }
    />
    <Route
      path={BRACKET_URL}
      element={
        <Suspense fallback={<BasePage />}>
          <Bracket />
        </Suspense>
      }
    />
    <Route element={<GamificationContextLayout />}>
      <Route
        path={ACHIEVEMENTS_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <Achievements />
          </Suspense>
        }
      />
      <Route
        path={BENEFITS_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <Benefits />
          </Suspense>
        }
      />
      <Route
        path={`${BADGES_URL}/:badgeCode/info`}
        element={
          <Suspense fallback={<BasePage />}>
            <SingleBadge />
          </Suspense>
        }
      />
      <Route
        path={BADGES_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <Badges />
          </Suspense>
        }
      />
      <Route path={HOME_URL} element={<Homepage />} />
      <Route path={`${HOME_URL}/:tab`} element={<Homepage />} />
      <Route
        path={PICKEM_URL}
        element={
          <PickemProvider>
            <Suspense fallback={<BasePage />}>
              <Pickem />
            </Suspense>
          </PickemProvider>
        }
      />
      <Route
        path={PREDICTOR_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <Predictor />
          </Suspense>
        }
      />
      <Route
        path={PLLN_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <PLLNProfile />
          </Suspense>
        }
      />
      <Route
        path={`${PLLN_PROFILE_URL}/:publicId`}
        element={
          <Suspense fallback={<BasePage />}>
            <PLLNProfile />
          </Suspense>
        }
      />
      <Route
        path={PLLN_PROFILE_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <PLLNProfile />
          </Suspense>
        }
      />
      <Route
        path={PLLN_EDIT_PROFILE_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <EditProfile />
          </Suspense>
        }
      />
      <Route
        path={`${CODE_CLAIM_URL}`}
        element={
          <Suspense fallback={<BasePage />}>
            <CodeClaim />
          </Suspense>
        }
      />
      <Route
        path={`${CODE_CLAIM_URL}/:code`}
        element={
          <Suspense fallback={<BasePage />}>
            <CodeClaim />
          </Suspense>
        }
      />
      <Route
        path={`${CODE_REDEEM_URL}`}
        element={
          <Suspense fallback={<BasePage />}>
            <CodeClaim />
          </Suspense>
        }
      />
      <Route
        path={`${CODE_REDEEM_URL}/:code`}
        element={
          <Suspense fallback={<BasePage />}>
            <CodeClaim />
          </Suspense>
        }
      />
      <Route
        path={RELEASE_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <Release />
          </Suspense>
        }
      />
      <Route
        path={OPT_IN_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <OptIn />
          </Suspense>
        }
      />
    </Route>

    <Route element={<FantasyContextLayout />}>
      <Route
        path={FANTASY_URL}
        element={
          <Suspense fallback={<BasePage />}>
            <FantasyTeam />
          </Suspense>
        }
      />

      <Route
        path={`${FANTASY_TEAM_URL}`}
        element={
          <Suspense fallback={<BasePage />}>
            <FantasyTeam />
          </Suspense>
        }
      />
      <Route element={<FantasyChallengersContextLayout />}>
        <Route
          path={`${FANTASY_MATCHUP_URL}/:challengerId`}
          element={
            <Suspense fallback={<BasePage />}>
              <FantasyMatchup />
            </Suspense>
          }
        />
        </Route>
      <Route element={<FantasyGroupContextLayout />}>
        <Route
          path={`${FANTASY_GROUPS_URL}`}
          element={
            <Suspense fallback={<BasePage />}>
              <FantasyGroupProvider>
                <FantasyGroups />
              </FantasyGroupProvider>
            </Suspense>
          }
        />
        <Route
          path={`${FANTASY_GROUPS_ENTER_COMPETITION_URL}`}
          element={
            <Suspense fallback={<BasePage />}>
              <FantasyGroupProvider>
                <FantasyGroupsEnterCompetition />
              </FantasyGroupProvider>
            </Suspense>
          }
        />

        <Route
          path={`${FANTASY_GROUPS_URL}/:groupId`}
          element={
            <Suspense fallback={<BasePage />}>
              <SingleFantasyGroup />
            </Suspense>
          }
        />
        <Route
          path={`${FANTASY_GROUPS_URL}/:groupId/edit-group`}
          element={
            <Suspense fallback={<BasePage />}>
              <EditFantasyGroup />
            </Suspense>
          }
        />
        <Route
          path={`${FANTASY_GROUPS_URL}/:groupId/edit-group-members`}
          element={
            <Suspense fallback={<BasePage />}>
              <EditFantasyGroupMembers />
            </Suspense>
          }
        />
        <Route
          path={`${FANTASY_GROUPS_COMPETITION_URL}/:competitionId`}
          element={
            <Suspense fallback={<BasePage />}>
              <FantasySingleGroupCompetition />
            </Suspense>
          }
        />
      </Route>
      <Route
        path={`${FANTASY_PLAYERS_URL}`}
        element={
          <Suspense fallback={<BasePage />}>
            <FantasyPlayers />
          </Suspense>
        }
      />
    </Route>
    <Route path="*" element={<Error errorNo={404} />} />
  </Routes>
)
