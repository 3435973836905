export const logBrazeEvent = async (
  name: string,
  params?: any | undefined
) => {
  try {
    if (window?.braze?.logCustomEvent) {
      window.braze.logCustomEvent(name, params)
      return
    }
    const braze = await import('@braze/web-sdk')
    braze.logCustomEvent(name, params)
  } catch (e) {
    console.log('Braze SDK load error:', e)
  }
}

export const setBrazeAttribute = async (
  attributeName: string,
  property: any
) => {
  try {
    if (window?.braze?.getUser) {
      const brazeUser = window.braze.getUser()
      window?.braze?.setUserAttribute &&
        brazeUser.setUserAttribute(attributeName, property)
      return
    }
    const braze = await import('@braze/web-sdk')
    const brazeUser = braze.getUser()
    if (brazeUser) {
      brazeUser.setCustomUserAttribute(attributeName, property)
    }
  } catch (e) {
    console.log(e)
  }
}
