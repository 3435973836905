import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import { FantasyContext } from './FantasyContext'
import {
  CompiledPicksType,
  FantasyUser,
} from '../../Api/Fantasy/fantasy.types'
import { SEASON } from '../../Configs/config'
import { fetchFantasyChallenger } from '../../Api/Fantasy/fantasy.api'
import { defaultCompiledPicks } from '../../Configs'
import { logEvent } from '../../Utils'
import { GeneralSnackbar } from '../../Components/Snackbar/GeneralSnackbar'
import { SeasonContext } from '../SeasonContext'
import { useMatchupURLParams } from '../utils'
import { AuthContext } from '../../Api/firebase'
import { STORAGE_NAMES } from '../../Constants'
import { StorageContext } from '../StorageContext'

type FantasyChallengersContextType = {
  matchupUser: FantasyUser | null
  matchupChallenger: FantasyUser | null
  challengerLoading: boolean
  matchupCompiled: CompiledPicksType | null
  handleChallengerClick: (challenger: FantasyUser) => void
}

export const FantasyChallengersContext =
  createContext<FantasyChallengersContextType | null>(null)

export const FantasyChallengersProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const challengerParam = useMatchupURLParams().challengerId || null
  const { store, getStoredData } = useContext(StorageContext)!
  let { isLoggedIn } = useContext(AuthContext)!
  let {
    fantasyUser,
    currentWeek,
    modalState,
    snackbarData,
    setupPicksForWeek,
  } = useContext(FantasyContext)!
  let { seasonSelect } = useContext(SeasonContext)!
  let [matchupUser, setMatchupUser] = useState<FantasyUser | null>(null)
  const [challengerLoading, setChallengerLoading] =
    useState<boolean>(false)
  const [matchupCompiled, setMatchupCompiled] =
    useState<CompiledPicksType | null>(null)
  const [matchupChallenger, setMatchupChallenger] =
    useState<FantasyUser | null>(null)

  useEffect(() => {
    if (isLoggedIn) {
      Promise.resolve(checkStoredMatchupUser())
      if (challengerParam) {
        Promise.resolve(getMatchupUserInfo(challengerParam))
      }
      if (!challengerParam) return
    }
  }, [fantasyUser, challengerParam])

  const getMatchupUserInfo = async (challengerId: string) => {
    if (!fantasyUser) return
    setChallengerLoading(true)
    if (!challengerParam) {
      setChallengerLoading(false)
      return Promise.resolve()
    }

    let challenger = await fetchFantasyChallenger(
      challengerId,
      currentWeek,
      SEASON,
      seasonSelect.seasonType
    )
    let usr = await fetchFantasyChallenger(
      fantasyUser.firebaseId,
      currentWeek,
      SEASON,
      seasonSelect.seasonType
    )
    if (
      !challenger?.weekPick?.picks ||
      challenger.weekPick.picks.length < 1
    ) {
      const matchupPs = { ...defaultCompiledPicks }
      setMatchupCompiled(matchupPs)
      setChallengerLoading(false)
    } else {
      let picks = setupChallengerPicks(challenger)
      if (picks) {
        setMatchupChallenger(challenger)
        setChallengerLoading(false)
      }
    }
    if (usr) {
      store(STORAGE_NAMES.matchup_user, usr)
      setMatchupUser(usr)
    }
  }

  const setupChallengerPicks = (challenger: FantasyUser) => {
    if (
      !challenger?.weekPick?.picks ||
      challenger.weekPick.picks.length < 1
    ) {
      setMatchupCompiled({ ...defaultCompiledPicks })
      return null
    }

    const pickedPlayerIds = challenger.weekPick.picks.map(
      (pick) => pick.player.fantasyInfo.fantasyPlayerInfoId
    )

    let picks = setupPicksForWeek(pickedPlayerIds)
    setMatchupCompiled(picks)
    return picks
  }

  const handleChallengerClick = (challenger: FantasyUser) => {
    logEvent('fantasy_challenger_row_click', {
      week: currentWeek,
      challenger_id: challenger.firebaseId,
      challenger_team_name: challenger.teamName,
    })
  }

  const checkStoredMatchupUser = async () => {
    let storedUser = await getStoredData(STORAGE_NAMES.matchup_user)
    if (storedUser) {
      setMatchupUser(storedUser.data)
    }
  }

  return (
    <FantasyChallengersContext.Provider
      value={{
        matchupUser,
        handleChallengerClick,
        challengerLoading,
        matchupCompiled,
        matchupChallenger,
      }}
    >
      {children}
      {modalState.showSnackbar && snackbarData && (
        <GeneralSnackbar
          text={snackbarData.text}
          color={snackbarData.color}
          show={modalState.showSnackbar}
        />
      )}
    </FantasyChallengersContext.Provider>
  )
}
