import { callGraphQL, makeAPIRequest } from '../utils/request'
import { fetchFantasyPlayerQuery } from './player.queries'
import {
  AllPlayerInfoRes,
  PlayerWeekFantasyData,
  FantasyPlayerRes,
  FantasyPlayerInfo,
  FantasyPlayerStats,
  FantasyPlayerStatsRes,
} from '../Fantasy/fantasy.types'
import { FANTASY_ENDPOINTS } from '../utils/restEndpoints'

export const fetchAllPlayers = async (): Promise<
  FantasyPlayerInfo[] | null
> => {
  try {
    const playersRes = await makeAPIRequest<AllPlayerInfoRes>(
      `${FANTASY_ENDPOINTS.FANTASY}/${FANTASY_ENDPOINTS.PLAYERS}`,
      {
        method: 'GET',
        apiVersion: '4',
        headerParams: {
          Authorization:
            `Bearer ${process.env.REACT_APP_API_ACCESS_KEY}` || '',
        },
      }
    )
    if (playersRes?.error) {
      playersRes.error?.message &&
        console.error(playersRes.error?.message)
      return null
    }
    return playersRes?.data
  } catch (err) {
    return []
  }
}

export async function fetchPlayersWeeklyData({
  week,
}: {
  week: number
}): Promise<PlayerWeekFantasyData[]> {
  try {
    const playersRes = await makeAPIRequest<FantasyPlayerRes>(
      `${FANTASY_ENDPOINTS.FANTASY}/${FANTASY_ENDPOINTS.PLAYERS}/week/${week}`,
      {
        method: 'GET',
        apiVersion: '4',
        headerParams: {
          Authorization:
            `Bearer ${process.env.REACT_APP_API_ACCESS_KEY}`,
        },
      }
    )
    if (playersRes?.error) {
      playersRes.error?.message &&
        console.error(playersRes.error?.message)
      return []
    }
    return playersRes?.data
  } catch (err) {
    return []
  }
}

export async function fetchFantasyPlayer(
  id: string | null,
  year: number,
  week: number | null,
  segment: string
): Promise<FantasyPlayerStats | null> {
  let seg = segment === 'champseries' ? 'event' : segment
  try {
    const playersRes = await callGraphQL<FantasyPlayerStatsRes>(
      fetchFantasyPlayerQuery,
      {
        id,
        year,
        week,
        segment: seg,
        includeCS: segment === 'champseries',
      }
    )
    if (!playersRes?.player) return null
    return playersRes.player
  } catch (err) {
    return null
  }
}
