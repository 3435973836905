import { useLocation } from 'react-router-dom'
import {
  BRANCH_BASE_URL,
  FREE_TO_PLAY_URL,
  MAIN_PLL_URL,
  PLL_IMAGES,
} from '../../Configs/config'

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

type DynamicLinkParam = {
  title: string
  value?: string | number
}

export const createPickemDynamicLink = () => {
  let pageId = 'pickem'
  let webUrl = `${FREE_TO_PLAY_URL}pickem`
  const params = [
    {
      title: 'st',
      value: 'PLL%20PickEm',
    },
    {
      title: 'si',
      value: PLL_IMAGES.pickemLogo,
    },
    {
      title: 'afl',
      value: `${FREE_TO_PLAY_URL}pickem`,
    },
  ]
  return createDynamicLink(params, pageId, webUrl)
}

export const createCompetitionDynamicLink = (
  competition: string,
  competitionName: string,
  appOnly: boolean = false
) => {
  let pageId = 'PLLNation'
  let webUrl = `${FREE_TO_PLAY_URL}?competition=${competition}`
  let params = [
    {
      title: 'competition',
      value: competition,
    },
    {
      title: 'tabName',
      value: 'competitions',
    },
  ]

  if (!appOnly) {
    params = [
      ...params,
      {
        title: '$ios_url',
        value: webUrl,
      },
      {
        title: '$android_url',
        value: webUrl,
      },
      {
        title: '$desktop_url',
        value: webUrl,
      },
    ]
  }

  const social = {
    title: `PLL ${competitionName}`,
    image: PLL_IMAGES.pickemLogo,
  }
  return createDynamicLink(params, pageId, webUrl, social)
}

export const createDynamicLink = (
  params: DynamicLinkParam[],
  pageId: string,
  webUrl?: string,
  socialParams?: SocialParams
) => {
  const webLink = webUrl || `https://${MAIN_PLL_URL}/`
  const paramsForLongLink = [
    ...params,
    { title: 'pageID', value: pageId },
  ]
  const dLink = createLongLink(webLink, paramsForLongLink, socialParams)
  return dLink
}

type SocialParams = {
  title?: string
  description?: string
  image?: string
}

export const createLongLink = (
  link: string,
  params: DynamicLinkParam[],
  socialParams?: SocialParams
) => {
  //Needed with custom branch domain
  let linkParams = `?$canonical_url=${encodeURIComponent(link)}`
  if (params.length > 0) {
    linkParams += createLinkDataString(params)
  }
  if (socialParams) {
    if (socialParams.title) {
      const title = encodeURIComponent(socialParams.title)
      linkParams += `&$og_title=${title}`
      linkParams += `&$twitter_title=${title}`
    }
    if (socialParams.description) {
      const description = encodeURIComponent(socialParams.description)
      linkParams += `&$og_description=${description}`
      linkParams += `&$twitter_description=${description}`
    }
    if (socialParams.image) {
      const image = encodeURIComponent(socialParams.image)
      linkParams += `&$og_image_url=${image}`
      linkParams += `&$twitter_image_url=${image}`
    }
  }
  if (linkParams.endsWith('&')) linkParams = linkParams.slice(0, -1)

  return `${BRANCH_BASE_URL}/${linkParams}`
}

const createLinkDataString = (params: DynamicLinkParam[]) => {
  if (params.length < 1) {
    return ''
  }
  let str = ''
  params.map((item) => {
    if (item.value) {
      str += `&${item.title}=${item.value}`
    }
  })
  return str
}

export const checkIsIOS = () => {
  if (typeof window === `undefined` || typeof navigator === `undefined`)
    return false
  return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}
