import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { initFirebase } from './Api/firebase'
import { initializeSentry } from './Configs/Sentry/sentry'
import { initializeBranch } from './Configs'

declare global {
  interface Window {
    google: any
    adsbygoogle: any
    bc: any
    braze?: any;
    brazeInitialized?: boolean
    branch?: any
  }
}
initFirebase()
initializeBranch()
initializeSentry()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
