import React from 'react'
import { styled } from '@mui/system'
import { colors } from '../../Components/consts'
import { CheckIcon } from '../LazyComponents/lazyIcons'

const CheckMarkCon = styled(CheckIcon)(
    () => ({
        color: colors.black,
    })
)

export const CheckMarkIcon = () => {
    return (
        <CheckMarkCon fontSize='small' />
    )
}
