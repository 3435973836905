export const summaryFields = [
  { title: 'Season', value: 'seasonRecord' },
  { title: 'This Week', value: 'weeklyRecord' },
  { title: 'Earned', value: 'expEarned' },
]

export const STATUS_VALUES = {
  FREE_AGENT: 1,
  CONTRIBUTOR: 2,
  STARTER: 3,
  CAPTAIN: 4,
  ALL_STAR: 5,
  MVP: 6
}

export enum GAMIFICATION_ENGAGEMENTS {
  LOG_IN = 'logged_in',
  ARTICLE_READ = 'ARTICLE_READ',
  ARTICLE_SHARE = 'ARTICLE_SHARE',
  VIDEO_WATCH = 'VIDEO_WATCH',
  VIDEO_SHARE = 'VIDEO_SHARE',
  FANTASY_GROUP_SHARE = 'FANTASY_GROUP_SHARE',
  DAILY_ACHIEVEMENT_CLICK = 'DAILY_ACHIEVEMENT_CLICK'
}

export const gamificationEngagements = {
  LOG_IN: GAMIFICATION_ENGAGEMENTS.LOG_IN,
  ARTICLE_READ: GAMIFICATION_ENGAGEMENTS.ARTICLE_READ,
  ARTICLE_SHARE: GAMIFICATION_ENGAGEMENTS.ARTICLE_SHARE,
  VIDEO_WATCH: GAMIFICATION_ENGAGEMENTS.VIDEO_WATCH,
  VIDEO_SHARE: GAMIFICATION_ENGAGEMENTS.VIDEO_SHARE,
  FANTASY_GROUP_SHARE: GAMIFICATION_ENGAGEMENTS.FANTASY_GROUP_SHARE,
  DAILY_ACHIEVEMENT_CLICK: GAMIFICATION_ENGAGEMENTS.DAILY_ACHIEVEMENT_CLICK,
} 

export const PLLN_STATUS_INFO_COLORS = {
  'MVP': '#2487dc',
  'All-Star': '#fbbc1f',
  'Captain': '#b7b7b7',
  'Starter': '#bb4d02',
  'Contributor': '#993245',
  'Free Agent': '#7599b3',
}
