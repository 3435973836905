
import { callGraphQL, makeAPIRequest } from '../utils/request'
import { eventFanVotesByWeekQuery } from './events.queries'
import { gamificationEndpoints, type Event, type FetchEvent } from '../index'

export type SeasonEventsResponse = {
   items: Event[]
 }
 
/**
 * Fetch user and all user related data
 */
export async function fetchEventsByYear(
   season: number,
   includeCS?: boolean
 ): Promise<Event[]> {
   const response = await makeAPIRequest(gamificationEndpoints.EVENTS, {
     method: 'GET',
     apiVersion: '4',
     queryParams: {
       year: season,
       includeCS: includeCS ? `${includeCS}` : `false`,
     },
   })
   if (!response?.data) return []
   return response.data
 }

/**
 * Fetch just fan votes for each game in a given week
 */
export async function fetchEventFanVotesByWeek(
   year: number,
   week: number
): Promise<Event[]> {
  const eventRes = await callGraphQL<FetchEvent>(eventFanVotesByWeekQuery, {
   year,
   week: week.toString()
  })
  if(!eventRes?.seasonEvents) return []
   return eventRes.seasonEvents
}