export const initializeBranch = async () => {
  if (!process.env.REACT_APP_BRANCH_KEY) return false
  try {
    const branch = await import('branch-sdk')
    branch.init(process.env.REACT_APP_BRANCH_KEY, {}, (err, data) => {
      if (err) {
        console.error('Error initializing Branch', err)
        return false
      }
      return true
    })
    return true
  } catch (err) {
    console.error('Issue init Branch', err)
    return false
  }
}
